import { useState, useEffect, useRef } from 'react'
import { useAuth } from '../contexts/auth'
import { useRegion } from '@/contexts/region-context'

export default function useLocation(setUserDeniedLocation) {
  const { selectedRegionId } = useRegion()
  const [{ isAuthenticated }] = useAuth()
  const [location, setLocation] = useState({})
  const [locationIsResolving, setLocationIsResolving] = useState(true)

  const mountedRef = useRef()

  useEffect(() => {
    mountedRef.current = true
    return () => (mountedRef.current = false)
  }, [])

  useEffect(() => {
    if (!isAuthenticated && !!!selectedRegionId) {
      let navigator = window.navigator
      if (!navigator || !navigator.geolocation) return
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          if (mountedRef.current) {
            let geoLocationCoords = {
              lat: coords.latitude,
              lng: coords.longitude,
            }

            setLocation(geoLocationCoords)
            setLocationIsResolving(false)
          } else {
            setLocationIsResolving(false)
          }
        },
        () => {
          // user denied geolocation prompt, or some other error
          setLocationIsResolving(false)
          if (setUserDeniedLocation) setUserDeniedLocation(true)
        },
      )
    } else {
      setLocationIsResolving(false)
    }
  }, [isAuthenticated, selectedRegionId, setUserDeniedLocation])

  return [location, locationIsResolving]
}
