import loadScript from './load-script'

async function waitForDYO(retries = 5, interval = 200) {
  while (retries > 0) {
    if (window.DYO) {
      return true
    }
    await new Promise((resolve) => setTimeout(resolve, interval))
    retries--
  }
  return false
}

export default async function setDYContext(type, data, locale) {
  const dynamicYieldId = process.env.NEXT_PUBLIC_DYNAMIC_YIELD_ID
  const language = /en/i.test(locale) ? 'en_CA' : 'fr_CA'

  window.DY = window.DY || {}
  DY.recommendationContext = { type: type, lng: language, ...(!!data ? { data: data } : {}) }
  DY.userActiveConsent = { accepted: true }

  const dynamicScriptSrc = `//cdn.dynamicyield.com/api/${dynamicYieldId}/api_dynamic.js`
  const staticScriptSrc = `//cdn.dynamicyield.com/api/${dynamicYieldId}/api_static.js`

  try {
    if (!document.querySelector(`script[src="${dynamicScriptSrc}"]`)) {
      await loadScript({ src: dynamicScriptSrc, type: 'text/javascript', append: 'head' })
    }

    if (!document.querySelector(`script[src="${staticScriptSrc}"]`)) {
      await loadScript({ src: staticScriptSrc, type: 'text/javascript', append: 'head' })
    }

    const isDYOReady = await waitForDYO()
    if (!isDYOReady) {
      console.error('Dynamic Yield scripts might be blocked by an ad blocker.')
      return false
    }
    return true
  } catch (error) {
    console.error(error)
    return false
  }
}
