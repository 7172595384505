const loadScript = ({ src, type = 'module', append = 'body', async = false, defer = false, isCss = false }) => {
  if (isCss) {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = src
    if (append === 'body') document.body.appendChild(link)
    else document.head.appendChild(link)
  } else {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = src
      script.type = type || 'text/javascript'
      if (async) script.async = true
      if (defer) script.defer = true

      script.onload = () => resolve()
      script.onerror = () => reject(new Error(`Failed to load script: ${src}`))

      const parent = append === 'head' ? document.head : document.body
      parent.appendChild(script)
    })
  }
}

export default loadScript
