import { useState } from 'react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { setCoveoTokensToCookie } from '@/utils/coveoToken'
import { useAuth } from '@/contexts/auth'
import { getJWTToken } from '@/utils/token'
import useCoveoScripts from '@/hooks/use-coveo-scripts'
import Home from '@/containers/home'
import Loader from '@/components/loader'
import setDYCookie from '@/utils/set-dy-cookie'

const HomePage = ({}) => {
  const [{ isAuthenticated, activeProfile }] = useAuth()
  const [isWindowObjectLoaded, setIsWindowObjectLoaded] = useState(false)
  useCoveoScripts({ setIsWindowObjectLoaded })

  if ((isAuthenticated && !!!activeProfile?.id) || !isWindowObjectLoaded) return <Loader delay={300} fullPage />

  return <Home />
}

export default HomePage

export const getServerSideProps = async (context) => {
  const { locale } = context

  const token = await getJWTToken(context.req?.headers.cookie)
  await setCoveoTokensToCookie(context)

  setDYCookie(context)

  return {
    props: {
      token,
      ...(await serverSideTranslations(locale, ['common', 'header', 'footer', 'switchProfile', 'pager'])),
    },
  }
}
