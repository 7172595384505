import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import isNotEmptyObject from '@/utils/is-not-empty-object'
import { useAuth } from '@/contexts/auth'
import { useFeatureToggle } from '@/contexts/feature-toggle'
import { parseJsonData } from '@/utils/parse-json-data'
import { getSpecAttributeFacets, getSpecAttributeFacetsKey } from '@/api-client/category/get-spec-attribute-facets'
import { useQuery } from '@tanstack/react-query'
import { useRegion } from '@/contexts/region-context'

export default function useCoveoScripts({ isCategoryPage = false, isSearchPage = false, categoryName = '', setIsWindowObjectLoaded }) {
  const { selectedRegionId } = useRegion()
  const [{ isAuthenticated, customerDataForCoveo: customer, permissions }] = useAuth()
  const { locale } = useRouter()
  const { t } = useTranslation(['category', 'common', 'stockIcons'])
  const [{ featureToggles }] = useFeatureToggle()

  let regionIdFromClient = selectedRegionId
  if ((regionIdFromClient === undefined || regionIdFromClient === 0) && process.env.NODE_ENV === 'development') regionIdFromClient = 1

  const languageIdFromClient = locale === 'fr' && (regionIdFromClient === 1 || regionIdFromClient === 5) ? 2 : 1

  const { data } = useQuery({
    queryKey: [getSpecAttributeFacetsKey],
    queryFn: () => getSpecAttributeFacets({ languageIdFromClient }),
    enabled: !!languageIdFromClient && (isSearchPage || isCategoryPage),
  })

  useEffect(() => {
    if (
      isNotEmptyObject(featureToggles) &&
      (((isCategoryPage || isSearchPage) && isNotEmptyObject(data?.specAttributeFacets_Serialized)) || !(isCategoryPage || isSearchPage)) &&
      (!isAuthenticated || (isAuthenticated && isNotEmptyObject(customer)))
    ) {
      const commonTranslations = {
        'search-box-placeholder': t('search-box-placeholder', { ns: 'common' }),
        'search-box-mobile-placeholder': t('search-box-mobile-placeholder', { ns: 'common' }),
        'wca.popularbought': t('wca.popularbought', { ns: 'common' }),
        'wca-trending-searches': t('wca-trending-searches', { ns: 'common' }),
        'wca.categories': t('wca.categories', { ns: 'common' }),
      }

      let PLPSRPTranslations = {}

      if (isSearchPage || isCategoryPage) {
        PLPSRPTranslations = {
          'wca.bestseller': t('wca.bestseller'),
          'wca.specialoffers': t('wca.specialoffers'),
          'wca-last-ordered-date': t('wca-last-ordered-date'),
          'wca.signinfor': t('wca.signinfor'),
          'wca-add-to-cart': t('wca-add-to-cart'),
          'showing-results-of': t('wca.showingresults'),
          'showing-results-of_plural': t('wca.showingresultsplural'),
          'results-per-page': t('wca.resultsperpage'),
          'wca-alternate-products': t('wca-alternate-products'),
          'wca.itemhasbeenrenamed': t('wca.itemhasbeenrenamed'),
          'wca.itemisnolonger': t('wca.itemisnolonger'),
          'wca.itemrecommendedsubstitute': t('wca.itemrecommendedsubstitute'),
          'wca.qty': t('wca.qty'),
          'wca.noQtyErrorMsg': t('wca.noQtyErrorMsg'),
          'wca.qtyErrorMsg1': t('wca.qtyErrorMsg1'),
          'wca.qtyErrorMsg2': t('wca.qtyErrorMsg2'),
          'wca.addtolist': t('wca.addtolist'),
          'wca.uom': t('wca.uom', { ns: 'common' }),
        }
      }

      const wcaTranslations = {
        en: {
          ...commonTranslations,
          ...PLPSRPTranslations,
          'wca-tooltip-1': 'Item is available at your branch.',
          'wca-tooltip-2': 'Item is available. Check availability tab for quantities at your branch and other locations.',
          'wca-tooltip-3': "This item will be ordered in for you. We'll be in touch with availability details.",
        },
        fr: {
          ...commonTranslations,
          ...PLPSRPTranslations,
          'wca-tooltip-1': 'Le produit est disponible à votre succursale.',
          'wca-tooltip-2': "Le produit est disponible. Vérifiez l'inventaire des succursales à partir de l'onglet Disponibilité.",
          'wca-tooltip-3': 'Ce produit sera commandé pour vous. Nous communiquerons avec vous pour les détails.',
        },
      }

      window.coveoEnvSettings = {
        orgId: process.env.NEXT_PUBLIC_COVEO_ORGANIZATION_ID,
        platformUrl: process.env.NEXT_PUBLIC_COVEO_PLATFORM_URL,
        searchUrl: process.env.NEXT_PUBLIC_COVEO_TOKEN_URL,
        apiDomain: process.env.NEXT_PUBLIC_DOMAIN,
        apiKey: process.env.NEXT_PUBLIC_COVEO_API_KEY,
        languageId: locale == 'fr' ? 2 : 1,
        regionId: selectedRegionId,
        isAuthenticated: isAuthenticated,
        searchHubProductWXEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductWXEn,
        searchHubProductWZEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductWZEn,
        searchHubProductQPEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductQPEn,
        searchHubProductQPFr: process.env.NEXT_PUBLIC_COVEO_SearchHubProductQPFr,
        searchHubProductMGEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductMGEn,
        searchHubProductMGFr: process.env.NEXT_PUBLIC_COVEO_SearchHubProductMGFr,
        searchHubProductListingWXEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductListingWXEn,
        searchHubProductListingWZEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductListingWZEn,
        searchHubProductListingQPEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductListingQPEn,
        searchHubProductListingQPFr: process.env.NEXT_PUBLIC_COVEO_SearchHubProductListingQPFr,
        searchHubProductListingMGEn: process.env.NEXT_PUBLIC_COVEO_SearchHubProductListingMGEn,
        searchHubProductListingMGFr: process.env.NEXT_PUBLIC_COVEO_SearchHubProductListingMGFr,
        recSearchHubProductWXEn: process.env.NEXT_PUBLIC_COVEO_RecSearchHubProductWXEn,
        recSearchHubProductWZEn: process.env.NEXT_PUBLIC_COVEO_RecSearchHubProductWZEn,
        recSearchHubProductQPEn: process.env.NEXT_PUBLIC_COVEO_RecSearchHubProductQPEn,
        recSearchHubProductQPFr: process.env.NEXT_PUBLIC_COVEO_RecSearchHubProductQPFr,
        recSearchHubProductMGEn: process.env.NEXT_PUBLIC_COVEO_RecSearchHubProductMGEn,
        recSearchHubProductMGFr: process.env.NEXT_PUBLIC_COVEO_RecSearchHubProductMGFr,
        searchHubAvailability: process.env.NEXT_PUBLIC_COVEO_SearchHubAvailability,
      }

      window.wolseleyMaster = {
        properties: {
          wcaTranslations: wcaTranslations,
          isCoveoGTMImpressionsEnabled: featureToggles?.isCoveoGTMImpressionsEnabled,
          isURLStructureEnabled: featureToggles?.isURLStructureEnabled,
          isAuthenticated: isAuthenticated,
          isCategoryPage: isCategoryPage,
          isSearchPage: isSearchPage,
          isSignalREnabled: 'False',
          isAvailabilityToggleOn: true,
          useDualStockIcons: featureToggles?.UseDualStockIcons,
          permissionCart: permissions?.cart,
          permissionPrice: permissions?.prices,
          facetInfo: isCategoryPage || isSearchPage ? parseJsonData(data?.specAttributeFacets_Serialized) : [],
          detectTouchscreen: function () {
            var result = false
            if (window.PointerEvent && 'maxTouchPoints' in navigator) {
              // if Pointer Events are supported, just check maxTouchPoints
              if (navigator.maxTouchPoints > 0) {
                result = true
              }
            } else {
              // no Pointer Events...
              if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
                // check for any-pointer:coarse which mostly means touchscreen
                result = true
              } else if (window.TouchEvent || 'ontouchstart' in window) {
                // last resort - check for exposed touch events API / event handler
                result = true
              }
            }
            return result
          },
        },
      }

      if (isAuthenticated && isNotEmptyObject(customer || {})) {
        window.customerData = {
          customer_Email: customer.DboCustomer.Email,
          customer_CreatedOnUtc: customer.DboCustomer.CreatedOnUtc,
          customer_LastActivityDateUtc: customer.DboCustomer.LastActivityDateUtc,
          customer_FirstName: customer.Login.FirstName,
          customer_LastName: customer.Login.LastName,
          customer_Title: customer.Profile.Name,
          customer_LoginInternalUser: customer.Login.LoginInternalUser,
          customer_CustomerNumber: customer.Account.CustomerNumber,
          customer_CustomerName: customer.Account.CustomerNumber,
          customer_AddressLine1: customer.Account.AddressLine1,
          customer_AddressLine2: customer.Account.AddressLine2,
          customer_PostalCode: customer.Account.PostalCode,
          customer_Company: customer.Account.Company,
          customer_Division: customer.Account.Division,
          customer_CustomerStatus: customer.Account.CustomerStatus,
          customer_SICCode: customer.Account.SICCode,
          customer_PreferredBranch: customer.Profile.PreferredBranch,
          customer_CustomerNumberFull: customer.CustomerNumberEncoded,
          customer_RegionId: customer.RegionId,
          customer_LanguageId: customer.LanguageId,
        }
      }

      if (isCategoryPage || isSearchPage) {
        window.availabilityMessage = {
          properties: {
            availableForPickup: t('wca.availableforpickup', { ns: 'stockIcons' }),
            availableForPickupPlurals: t('wca.availableforpickupplural', { ns: 'stockIcons' }),
            checkStockAtOtherBranches: t('wca.checkstockatotherbranches', { ns: 'stockIcons' }),
            availableAtOtherBranches: t('wca.availableatotherbranches', { ns: 'stockIcons' }),
            notAvailableForPickup: t('wca.notavailableforpickup', { ns: 'stockIcons' }),
            availableForDelivery: t('wca.availablefordelivery', { ns: 'stockIcons' }),
            availableForDeliveryPlural: t('wca.availablefordeliveryplural', { ns: 'stockIcons' }),
            deliveryWhenItemAvailable: t('wca.deliverywhenitemavailable', { ns: 'stockIcons' }),
            currentLocation: t('wca.availableat', { ns: 'stockIcons' }),
            otherLocationPartOne: t('wca.availableatother'),
            otherLocationPartTwo: t('wca.locationsavailability'),
            otherLocation: t('wca.otherlocations', { ns: 'stockIcons' }),
            backOrder: t('wca.backorder', { ns: 'stockIcons' }),
            backOrderTooltip: t('wca.backordertooltip', { ns: 'stockIcons' }),
            seoCode: locale,
          },
        }

        window.wolseleyChoiceMessage = {
          properties: {
            wolseleyChoicelabel: t('wca.wolseleyschoice'),
          },
        }

        window.categoryPath = categoryName
      }

      setIsWindowObjectLoaded(true)
    }
  }, [isAuthenticated, customer, data, featureToggles])
}
